import * as R from 'ramda'
import {Link} from 'gatsby'
import React, {useRef, useState} from 'react'

import {blogListPropTypes} from 'helpers/propTypes'
import Filter from 'components/UI/Filter'
import FiltersContainer from 'components/UI/FiltersContainer'
import InfiniteScrollObserver from 'components/UI/InfiniteScrollObserver'
import LoadMore from 'components/UI/LoadMore'
import Section from 'components/UI/Section'
import useGraphQLFiltersInsights from 'hooks/useGraphQLFiltersInsights'
import useHashConverter from 'hooks/useHashConverter'
import useHashReader from 'hooks/useHashReader'
import useIsMobile from 'hooks/useIsMobile'
import useObserver from 'hooks/useObserver'

import useStyles from './styles'

const BlogList = ({
  countryInsights,
  countryInsightsIds,
  industriesTags,
  services,
  typesTags,
  searchPlaceholder,
  filterByIndustryLabel,
  filterByServiceLabel,
  filterByTypeLabel,
  filterSeeMoreLabel,
  filterSeeLessLabel,
  pageContext,
  countryData,
  location,
  whitePapers,
  whitePaperType,
  featuredArticles,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)

  const loadRef = useRef()
  const [limit, setLimit] = useState(10)
  const [loadMore, setLoadMore] = useState(false)
  const [infiniteScroll, setInfiniteScroll] = useObserver(
    loadRef,
    loadMoreItems,
  )

  const [hashConditions] = useHashReader(location)
  const {hashIsEmpty} = hashConditions
  const [selectedTags] = useHashConverter()

  const itemsToRender = useGraphQLFiltersInsights(
    countryInsightsIds,
    countryInsights,
    pageContext,
    countryData,
    selectedTags,
    typesTags,
    industriesTags,
    services,
  )

  const mergedAndSorted = [...whitePapers, ...itemsToRender].sort((a, b) => {
    const dateA = new Date(a.publicationDate)
    const dateB = new Date(b.publicationDate)

    return dateB - dateA
  })

  const removeMatchingItems = (smallArray, bigArray) => {
    const slugsToRemove = R.pluck('slug', smallArray)

    return R.filter(item => !R.includes(item.slug, slugsToRemove), bigArray)
  }
  const filteredArray = removeMatchingItems(featuredArticles, mergedAndSorted)

  const finalItems = hashIsEmpty
    ? R.slice(0, limit, filteredArray)
    : filteredArray

  const hasMoreItems = limit <= R.length(filteredArray)

  function loadMoreItems() {
    if (hasMoreItems) {
      setLoadMore(true)
      setTimeout(() => {
        setLimit(val => val + 10)
        setLoadMore(false)
      }, 1000)
    }
  }

  const handleLoadMore = () => {
    setInfiniteScroll(true)
    loadMoreItems()
  }

  const returnBlogList = () =>
    mapIndexed((node, index) => {
      const {
        image,
        type,
        publicationDate,
        metaDescription,
        title,
        thumbnail,
        description,
      } = node
      const {name} = type || 'White paper'
      const eulaSlug = node.slug === 'eula'

      const renderLinkByWebsite = () =>
        process.env.GATSBY_WEBSITE === 'keyrus-fondation'
          ? `../news/${node.slug}`
          : `../insights/${node.slug}`

      const renderNavigateTo = eulaSlug ? '../eula' : renderLinkByWebsite()

      return (
        <Link
          to={renderNavigateTo}
          className={classes.blogContainer}
          key={index}
        >
          <div className={classes.card}>
            {image && (
              <img
                src={image.url || image.file}
                alt={image.url}
                loading="lazy"
              />
            )}
            {!image && thumbnail && (
              <img src={thumbnail.url} alt={thumbnail.url} loading="lazy" />
            )}
          </div>
          <div className={classes.blogContent}>
            <h3 className={classes.type}>
              {(type && name) || whitePaperType.name}
            </h3>
            <h2 className={classes.metaTitle}>{title && title}</h2>
            <p className={classes.date}>
              {new Date(publicationDate).toLocaleString('fr-FR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </p>
            <p className={classes.metaDescription}>
              {(metaDescription && metaDescription) || description}
            </p>
          </div>
        </Link>
      )
    }, finalItems)

  return (
    <>
      <Section hasPaddingTop={false}>
        <div className={classes.root}>
          <div className={classes.blogListRoot}>
            {returnBlogList()}

            {hashIsEmpty && hasMoreItems && (
              <LoadMore
                label={filterSeeMoreLabel}
                loadMore={loadMore}
                hasMoreItems={hasMoreItems}
                infiniteScroll={infiniteScroll}
                handleLoadMore={handleLoadMore}
              />
            )}
          </div>

          {!isMobile && (
            <div className={classes.filtersContainer}>
              <FiltersContainer searchPlaceholder={searchPlaceholder}>
                <Filter
                  array={typesTags}
                  filterLabel={filterByTypeLabel}
                  filterSeeMoreLabel={filterSeeMoreLabel}
                  filterSeeLessLabel={filterSeeLessLabel}
                />
                <Filter
                  array={industriesTags}
                  filterLabel={filterByIndustryLabel}
                  filterSeeMoreLabel={filterSeeMoreLabel}
                  filterSeeLessLabel={filterSeeLessLabel}
                  hasLongText
                />
                {services.length !== 0 && (
                  <Filter
                    array={services}
                    filterLabel={filterByServiceLabel}
                    filterSeeMoreLabel={filterSeeMoreLabel}
                    filterSeeLessLabel={filterSeeLessLabel}
                  />
                )}
              </FiltersContainer>
            </div>
          )}
        </div>
      </Section>

      <InfiniteScrollObserver show={infiniteScroll} Ref={loadRef} />
    </>
  )
}

BlogList.propTypes = blogListPropTypes
BlogList.defaultProps = {}

export default BlogList
